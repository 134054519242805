import { useEffect, useState } from "react";
import SecureLS from "secure-ls";
import Home from "../pages/home/home";

export const AuthorizeUser = ({ component: Component, role }) => {
  const [isAuthorizedRole, setIsAuthorizedRole] = useState(false);
  //const { getIdTokenClaims } = useAuth0();

  useEffect(() => {    
    async function checkRoles() {
      const ls = new SecureLS();
      const user = ls.get("userinfo");
      const userRole = user.role;
      //console.log(userRole);
      const roles = role.split(",");
      if (roles.includes(userRole)) {
        setIsAuthorizedRole(true);
      }
      //debugger;
    }
    checkRoles(role);
  }, [role]);

  return isAuthorizedRole ? Component : Home;
}