import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SecureLS from "secure-ls";
import DropDownButton from "devextreme-react/drop-down-button";
import "./user_info.scss";

const url = process.env.REACT_APP_API_URL + "/api";

function UserInfo(props) {
  const history = useHistory();
  const { logout } = useAuth0();
  const [adminRoleId, setAdminRoleId] = useState(0);
  const [userRoleId, setUserRoleId] = useState(0);

  const profileSettings = [
    { id: 1, name: "Logout", icon: "runner" },
    { id: 2, name: "User Management", icon: "group" },
    { id: 3, name: "Views Report", icon: "chart" },
  ];

  const onButtonClick = (e) => {
    history.push("/");
  };

  const onItemClick = (e) => {
    if (e.itemData.name === "Logout") {
      const ls = new SecureLS();
      ls.remove("userinfo");
      ls.remove("isAuthorized");
      logout({ logoutParams: { returnTo: window.location.origin } });
    } else if (e.itemData.name === "User Management") {
      history.push("/userAdmin");
    } else if (e.itemData.name === "Views Report") {
      history.push("/viewsReport");
    }
  };

  useEffect(() => {
    fetch(url + "/Admin/GetAdminRoleId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          setAdminRoleId(myJson);
        }
      });
  }, []);

    useEffect(() => {
        const ls = new SecureLS();
        const isAuthorized = ls.get("isAuthorized");

        if (props.userInfo !== undefined && isAuthorized === true) {
            fetch(
                url + "/Admin/GetUserRoleId?emailAddress=" + props.userInfo.emailAddress,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network Response was not ok");
                    }
                    return response.json();
                })
                .then((myJson) => {
                    if (typeof myJson.message !== "undefined") {
                        throw myJson.message;
                    } else {
                        setUserRoleId(myJson);
                    }
                });
        }
    }, [props.userInfo]);

  return (
    <div style={{ float: "right", marginRight: "50px" }}>
      {/* {props.userInfo===undefined && (
                <div>
                    <LoginButton />
                </div>
            )} */}

      {props.userInfo && props.userInfo.isActive && (
        <div className="user-info">
          <DropDownButton
            width="200px"
            stylingMode="contained"
            splitButton={true}
            useSelectMode={false}
            text={props.userInfo.firstName + " " + props.userInfo.lastName}
            icon="home"
            items={
              userRoleId === adminRoleId
                ? profileSettings
                : profileSettings.splice(
                    profileSettings.findIndex((item) => item.name !== "Admin"),
                    1
                  )
            }
            displayExpr="name"
            keyExpr="id"
            onButtonClick={onButtonClick}
            onItemClick={onItemClick}
          />
        </div>
      )}
    </div>
  );
}

export default UserInfo;
