import React, { Component } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataGrid, {
    Column,
    Paging,
    FilterRow,
    RequiredRule,
} from "devextreme-react/data-grid";

const url = process.env.REACT_APP_API_URL + "/api";

class ViewsReportTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activityLogData: new CustomStore({
                key: "departmentName",
                load: this.loadActivityLog,
            }),
            includeInactiveRecords: false,
            rolesData: [],
            departmentsData: [],
        };

        this.idPattern = /[A-Z]\d{4}$/;
        this.active = [
            { text: "Active", value: true },
            { text: "Inactive", value: false },
        ];
    }

    loadActivityLog = () => {
        return fetch(
            url +
            "/ActivityLogReport/GetActivityLogViews",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {

                if (!response.ok) {
                    throw new Error("Network Response was not ok");
                }
                return response.json();
            })
            .then((myJson) => {
                console.log("response", myJson)
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                } else {
                    return myJson;
                }
            });
    };


    render() {
        const { activityLogData } = this.state;

        return (
            <div style={{ padding: "20px" }}>
                <div className="option">
                </div>
                <br />
                <DataGrid
                    id="gridContainer"
                    dataSource={activityLogData}
                    allowColumnReordering={false}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    showBorders={true}
                    showColumnLines={true}
                    onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    ref={(ref) => (this.dataGrid = ref)}
                    onInitialized={this.onInitialized}
                    width="100%"
                >
                    <FilterRow visible={true} />
                    <Paging enabled={true} pageSize={25} />

                    <Column
                        dataField="departmentName"
                        dataType="string"
                        caption="Department Name"
                        allowSorting={true}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="viewCount"
                        dataType="string"
                        caption="View Count"
                        allowSorting={true}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="month"
                        dataType="string"
                        caption="Date"
                        allowSorting={true}
                    >
                        <RequiredRule />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default ViewsReportTable;