import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Paging,
  Button,
  Scrolling,
  Selection,
  Export,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import ViewPopup from "../view-popup/view_popup.js";
import "./check_image_table.scss";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
//import { exportDataGrid } from 'devextreme/exporter/exceljs/excelExporter';
import { exportDataGrid } from "devextreme/excel_exporter";

class CheckImageTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // checkImageList: null,
      showFilterRow: true,
      showHeaderFilter: true,
    };
    this.dataGrid = null;
    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);
  }

  render() {
    return (
      <div>
        <DataGrid
          ref={(ref) => {
            this.dataGrid = ref;
          }}
          rowAlternationEnabled={true}
          dataSource={this.props.checkImageList}
          keyExpr="checkImageId"
          showBorders={true}
          showColumnLines={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          width="100%"
          height="80vh"
          onExporting={this.onExporting}
        >
          <Scrolling mode="infinite" useNative={true} />
          <Paging defaultPageSize={10} />
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Selection mode="multiple" />
          <Export enabled={true} allowExportSelectedData={true} />

          <Toolbar>
            <Item location="before" name="exportButton" />
            <Item location="after" name="searchPanel" />
          </Toolbar>

          <Column
            dataField="accountNumber"
            caption="Account Number"
            minWidth={150}
            format={formatAccountNumber}
          />
          <Column
            dataField="postingDate"
            caption="Posting Date"
            dataType="date"
            minWidth={150}
            calculateFilterExpression={this.calculateFilterExpression}
          >
            <HeaderFilter dataSource={this.orderHeaderFilter} />
          </Column>
          <Column
            dataField="issueDate"
            caption="Issuing Date"
            dataType="date"
            minWidth={150}
            calculateFilterExpression={this.calculateFilterExpression}
          >
            <HeaderFilter dataSource={this.orderHeaderFilter} />
          </Column>
          <Column
            dataField="serialNumber"
            caption="Warrant Number"
            minWidth={150}
          />
          <Column
            dataField="amount"
            dataType="number"
            format={{ type: "currency", precision: 2 }}
            caption="Amount"
            minWidth={150}
            editorOptions={{ format: "currency", showClearButton: true }}
          />
          <Column type="buttons" caption="View" minWidth={150}>
            <Button
              autoFocus
              text={"View"}
              visible={true}
              disabled={false}
              component={ViewPopup}
            />
          </Column>
        </DataGrid>
      </div>
    );
  }

  onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    debugger;
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === "data") {
          if (gridCell.column.dataField === "accountNumber") {
            excelCell.value = formatAccountNumber(gridCell.value);
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "CheckImages.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  calculateFilterExpression(value, selectedFilterOperations, target) {
    const column = this;
    if (target === "headerFilter" && value === "weekends") {
      return [[getOrderDay, "=", 0], "or", [getOrderDay, "=", 6]];
    }
    return column.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    );
  }

  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: "Weekends",
        value: "weekends",
      });
      return results;
    };
  }

  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value,
    });
    this.clearFilter();
  }

  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value,
    });
    this.clearFilter();
  }

  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value,
    });
  }

  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }
}

function getOrderDay(rowData) {
  return new Date(rowData.OrderDate).getDay();
}

function formatAccountNumber(accountNumber) {
  return accountNumber.replace(/.(?=.{4})/g, "*");
}

export default CheckImageTable;
