import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import CheckComparison from "./check_comparison";
const CheckWarrantNumber = ({
  comparison,
  setComparison,
  warrantNumberFrom,
  setWarrantNumberFrom,
  warrantNumberTo,
  setWarrantNumberTo,
  error,
  ...props
}) => {
  const [varError, setError] = useState(false);

  return (
    <>
      <h5>Warrant Number</h5>
      <CheckComparison
        comparison={comparison}
        setComparison={setComparison}
        from={warrantNumberFrom}
        setTo={setWarrantNumberTo}
      />

      {comparison === "~" ? (
        <div style={{ display: "flex", alignContent: "center" }}>
          <TextField
            fullWidth
            label="Warrant Number From"
            id="warrantNumberFrom"
            value={warrantNumberFrom}
            error={varError}
            type="number"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 15);
              setWarrantNumberFrom(e.target.value);
            }}
            onKeyDown={(event) => {
              const key = event.key;
              const specialKeys = ["e", "+", "-", "shift", "."];
              if (specialKeys.includes(key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{ marginRight: "4px" }}
          />
          <TextField
            fullWidth
            label="Warrant Number To"
            id="warrantNumberFrom"
            // defaultValue={null}
            error={Boolean(varError)}
            type="number"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 15);
              setWarrantNumberTo(e.target.value);
            }}
            onKeyDown={(event) => {
              const key = event.key;
              const specialKeys = ["e", "+", "-", "shift", "."];
              if (specialKeys.includes(key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{ marginLeft: "4px" }}
          />
        </div>
      ) : (
        <TextField
          fullWidth
          label="Warrant Number"
          id="warrantNumber"
          value={warrantNumberFrom}
          // error={Boolean(varError)}
          type="number"
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 15);
            setWarrantNumberFrom(e.target.value);
          }}
          onKeyDown={(event) => {
            const key = event.key;
            const specialKeys = ["e", "+", "-", "shift", "."];
            if (specialKeys.includes(key)) {
              event.preventDefault();
            }
          }}
          size="small"
        />
      )}
      <hr />
    </>
  );
};

export default CheckWarrantNumber;