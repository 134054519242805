import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./utils/bootstrap/bootstrap.min.css";
// import 'devextreme/dist/css/dx.light.css';
import "./App.scss";
import "./dx-styles.scss";

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SecureLS from "secure-ls";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./_routes/ProtectedRoute";
import Home from "./pages/home/home";
import CheckImageViewer from "./pages/checkImageViewer/check_image_viewer.js";
import UserInfo from "./components/user-profile/user_info";
import UserAdmin from "./pages/userAdmin/user_admin";
import ViewsReport from "./pages/viewsReport/views_report";
import AppTitle from "./components/user-profile/app_title";

const url = process.env.REACT_APP_API_URL + "/api";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE;

function App() {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } =
    useAuth0();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    //console.log("isAuthenticated:" + (isAuthenticated ? "true" : "false"));
    getUserFromDB();
  }, [isAuthenticated, getAccessTokenSilently]);

  const getUserFromDB = () => {
    try {
      if (isAuthenticated) {
        getAccessTokenSilently({
          authorizationParams: {
            audience: audience,
            scope: scope,
          },
        }).then((accessToken) => {
          fetch(url + "/Auth/GetUserFromDB?email=" + user.email, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
            .then((result) => {
              if (result.ok) {
                return result.json();
              } else {
                if (result.status === 401) {
                }
              }
            })
            .then((myJson) => {
              const ls = new SecureLS();
              if (myJson && myJson.user && myJson.user.isActive) {
                ls.set("userinfo", myJson.user);
                ls.set("isAuthorized", true);
                setUserInfo(myJson.user);
              } else {
                alert("Access denied. Please contact adminsitrator.");
                ls.remove("userinfo");
                ls.remove("isAuthorized");
                logout({ logoutParams: { returnTo: window.location.origin } });
              }
            });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper">
      <div className="header">
        <div className="logo"></div>
        {userInfo !== undefined && (
          <Router>
            <AppTitle />
          </Router>
        )}
        <div>
          <Router>
            <UserInfo userInfo={userInfo} />
          </Router>
        </div>
      </div>
      <div className="mainContent">
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            {/*<ProtectedRoute path="/profile" component={UserProfile} />*/}
            <ProtectedRoute
              path="/CheckImageViewer"
              exact
              component={CheckImageViewer}
            />
            <ProtectedRoute
              path="/userAdmin"
              exact
              role="Administrator"
              component={UserAdmin}
            />
            <ProtectedRoute
              path="/viewsReport"
              exact
              role="Administrator"
              component={ViewsReport}
            />
          </Switch>
        </Router>
      </div>
      <div className="footer">
        Copyright © {new Date().getFullYear()} San Bernardino County
        Auditor-Controller/Treasurer/Tax Collector - All Rights Reserved
      </div>
    </div>
  );
}

export default App;
