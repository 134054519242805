import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckComparison from "./check_comparison";

const CheckWarrantPostDate = ({
  comparison,
  setComparison,
  warrantPostDateFrom,
  setWarrantPostDateFrom,
  warrantPostDateTo,
  setWarrantPostDateTo,
  ...props
}) => {
  return (
    <>
      <h5>Warrant Post Date</h5>
      <CheckComparison
        comparison={comparison}
        setComparison={setComparison}
        from={warrantPostDateFrom}
        setTo={setWarrantPostDateTo}
      />
      {comparison === "~" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", alignContent: "center" }}>
            <DatePicker
              label="From"
              value={warrantPostDateFrom}
              onChange={(newValue) => setWarrantPostDateFrom(newValue)}
              slotProps={{
                textField: { size: "small", fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
              sx={{ marginRight: "4px" }}
            />
            <DatePicker
              label="To"
              value={warrantPostDateTo}
              onChange={(newValue) => setWarrantPostDateTo(newValue)}
              slotProps={{
                textField: { size: "small", fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
              sx={{ marginLeft: "4px" }}
            />
          </div>
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            value={warrantPostDateFrom}
            onChange={(newValue) => {
              setWarrantPostDateFrom(newValue);
              setWarrantPostDateTo(newValue);
            }}
            slotProps={{
              textField: { size: "small", fullWidth: true },
              actionBar: { actions: ["clear"] },
            }}
          />
        </LocalizationProvider>
      )}
      {/* {comparison}
      {warrantDateFrom.format("DD/MM/YYYY")}
      {warrantDateTo.format("DD/MM/YYYY")} */}
      <hr />
    </>
  );
};

export default CheckWarrantPostDate;
