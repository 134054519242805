import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../../components/user-profile/login_button";
import CheckImageViewer from "../checkImageViewer/check_image_viewer";
import SecureLS from "secure-ls";

const Home = () => {
  //const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const ls = new SecureLS();
  const isAuthorized = ls.get("isAuthorized");
  const { user } = useAuth0();
  // useEffect(() => {
  //   callAPI();
  // }, [getAccessTokenSilently]);
  //   const [checkedList, setCheckedList] = useState([]);

  return (
    <div>
      {isAuthorized && user !== undefined ? (
        <CheckImageViewer />
      ) : (
        <div
          style={{
            fontSize: "16px",
            textAlign: "center",
            display: "table",
            margin: "0 auto",
          }}
        >
          <br />
          <br />
          <h4>Welcome to Check Image Viewer Solution</h4>
          <br />
          <br />
          <LoginButton />
        </div>
      )}
    </div>
    // <>
    //   {listData.map((item, index) => {
    //     return (
    //         <input
    //           type="checkbox"
    //           name="languages"
    //           value={item.value}
    //           //   onChange={handleSelect}
    //         />
    //         <CheckBox
    //           key={index}
    //           text={item.value}
    //           //   checked={this.accountNumbersData.indexOf(an) > -1}
    //         />
    //         <label>{item.value}</label>
    //     );
    //   })}
    // </>
  );
};

export default Home;
