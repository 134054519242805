import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

const CheckComparison = ({ comparison, setComparison, from, setTo }) => {
  useEffect(() => {
    if (comparison !== "~") {
      setTo(from);
    }
  }, [comparison, from, setTo]);

  const handleChange = (event) => {
    setComparison(event.target.value);
    //console.log(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, paddingBottom: "4px" }}>
      <FormControl fullWidth>
        {/* <FormLabel id="comparison-label">Comparison</FormLabel> */}
        <RadioGroup
          row
          id="comparison"
          value={comparison}
          defaultValue={"="}
          onChange={handleChange}
          style={{ justifyContent: "space-between" }}
        >
          <FormControlLabel
            value={"="}
            control={<Radio size="small" />}
            label="Equal"
          />
          <FormControlLabel
            value={">"}
            control={<Radio size="small" />}
            label="Greater Than"
          />
          <FormControlLabel
            value={"<"}
            control={<Radio size="small" />}
            label="Less Than"
          />
          <FormControlLabel
            value={"~"}
            control={<Radio size="small" />}
            label="Between"
          />
          {/* <FormControlLabel
            value={""}
            control={<Radio size="small" />}
            label="None"
            labelPlacement="start"
          /> */}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CheckComparison;
