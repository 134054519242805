import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CheckComparison from "./check_comparison";

const CheckAmount = ({
  comparison,
  setComparison,
  amountFrom,
  setAmountFrom,
  amountTo,
  setAmountTo,
  ...props
}) => {  
  return (
    <>
      <h5>Amount</h5>
      <CheckComparison
        comparison={comparison}
        setComparison={setComparison}
        from={amountFrom}
        setTo={setAmountTo}
      />

      {comparison === "~" ? (
        <div style={{ display: "flex", alignContent: "center" }}>
          <TextField
            fullWidth
            label="Amount From"
            id="amountFrom"
            type="number"
            value={amountFrom === -1 ? "" : amountFrom}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={(event) => {
              if (event.target.value.toString().length <= 15) {
                setAmountFrom(event.target.value === "" ? -1 : event.target.value);
              }
            }}
            onKeyDown={(event) => {
              const key = event.key;
              const specialKeys = ["e", "+", "-", "shift"];
              if (specialKeys.includes(key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{ marginRight: "4px" }}
          />
          <TextField
            fullWidth
            label="Amount To"
            id="amountTo"
            type="number"
            value={amountTo === -1 ? "" : amountTo}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={(event) => {
              if (event.target.value.toString().length <= 15) {
                setAmountTo(event.target.value === "" ? -1 : event.target.value);
              }
            }}
            onKeyDown={(event) => {
              const key = event.key;
              const specialKeys = ["e", "+", "-", "shift"];
              if (specialKeys.includes(key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{ marginLeft: "4px" }}
          />
        </div>
      ) : (
        <TextField
          fullWidth
          label="Amount"
          id="amount"
          type="number"
          value={amountFrom === -1 ? "" : amountFrom}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(event) => {
            if (event.target.value.toString().length <= 15) {
              setAmountFrom(event.target.value === "" ? -1 : event.target.value);
              setAmountTo(event.target.value === "" ? -1 : event.target.value);
            }
          }}
          onKeyDown={(event) => {
            const key = event.key;
            const specialKeys = ["e", "+", "-", "shift"];
            if (specialKeys.includes(key)) {
              event.preventDefault();
            }
          }}
          size="small"
        />
      )}
      <hr />
    </>
  );
};

export default CheckAmount;
