import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'devextreme-react/button';

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      text="Log In"
      icon="user"
      type="normal"
      stylingMode="contained"
      onClick={() => loginWithRedirect()}
    ></Button>
  );
};

export default LoginButton;
