import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckComparison from "./check_comparison";

const CheckWarrantIssueDate = ({
  comparison,
  setComparison,
  warrantIssueDateFrom,
  setWarrantIssueDateFrom,
  warrantIssueDateTo,
  setWarrantIssueDateTo,
  ...props
}) => {
  return (
    <>
      <h5>Warrant Issue Date</h5>
      <CheckComparison
        comparison={comparison}
        setComparison={setComparison}
        from={warrantIssueDateFrom}
        setTo={setWarrantIssueDateTo}
      />

      {comparison === "~" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", alignContent: "center" }}>
            <DatePicker
              label="From"
              emptyLabel="date"
              value={warrantIssueDateFrom}
              onChange={(newValue) => setWarrantIssueDateFrom(newValue)}
              slotProps={{
                textField: { size: "small", fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
              sx={{ marginRight: "4px" }}
            />
            <DatePicker
              label="To"
              emptyLabel="date"
              value={warrantIssueDateTo}
              onChange={(newValue) => setWarrantIssueDateTo(newValue)}
              slotProps={{
                textField: { size: "small", fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
              sx={{ marginLeft: "4px" }}
            />
          </div>
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            value={warrantIssueDateFrom}
            onChange={(newValue) => {
              setWarrantIssueDateFrom(newValue);
              setWarrantIssueDateTo(newValue);
            }}
            slotProps={{
              textField: { size: "small", fullWidth: true },
              actionBar: { actions: ["clear"] },
            }}
          />
        </LocalizationProvider>
      )}
      {/* {comparison}
      {warrantDateFrom.format("DD/MM/YYYY")}
      {warrantDateTo.format("DD/MM/YYYY")} */}
      <hr />
    </>
  );
};

export default CheckWarrantIssueDate;
