import React from "react";
import { useHistory } from "react-router-dom";
import "./user_info.scss";

function AppTitle() {
  const history = useHistory();

  return (
    <button
      className="title"
      onClick={() => {
        history.push("/");
      }}
    >
      Check Image Viewer
    </button>
  );
}

export default AppTitle;
