import React, { useState, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ImageViewer from "react-simple-image-viewer";
import { useReactToPrint } from "react-to-print";
import "./view_popup.scss";
import { formatCurrency, formatDate } from "../../_helper/helper";
import LoadingScreen from "../loading_panel";
import NoImage from "./img/DNE.png";
const url = process.env.REACT_APP_API_URL + "/api";

const ViewPopup = (...props) => {
  const componentPrintRef = useRef();
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const checkData = props[0].data.data;
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = async () => {
    setIsLoading(true);

    fetch(url + "/View/GetImages", {
      method: "POST",
      headers: {
        // Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(checkData),
    })
      .then(async (response) => {
        if (response.statusText === "Bad Request") {
          setOpen(true);
          setImages([NoImage, NoImage]);
          setIsLoading(false);
          return;
        } else {
          const data = await response.json();
          setImages(data.map((i) => "data:image/png;base64," + i));
          setOpen(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Handle other errors here
        console.error(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    documentTitle: "Check Image Number 1",
  });

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        View Image
      </Button>
      <LoadingScreen isLoading={isLoading} />
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{ width: `calc(100vw - 100px)`, margin: "0 auto" }}
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Check number 1"}
        </DialogTitle> */}

        <DialogContent
          style={{ width: "100%", textAlign: "center", padding: "0 0 20px 0" }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                display: "grid",
                alignContent: "center",
                position: "sticky",
                top: "0px",
                backgroundColor: "white",
              }}
            >
              <div>
                <p style={{ fontSize: 35 }}>Check Details</p>
                <table className="metadata-table">
                  <tbody>
                    <tr>
                      <th>Posting Date</th>
                      <td style={{ textAlign: "right" }}>
                        {formatDate(checkData.postingDate)}
                      </td>
                    </tr>
                    <tr>
                      <th>Issue Date</th>
                      <td style={{ textAlign: "right" }}>
                        {formatDate(checkData.issueDate)}
                      </td>
                    </tr>
                    <tr>
                      <th>Warrant Number</th>
                      <td style={{ textAlign: "right" }}>
                        {checkData.serialNumber}
                      </td>
                    </tr>
                    <tr style={{}}>
                      <th>Warrant Amount</th>
                      <td style={{ textAlign: "right" }}>
                        {formatCurrency(checkData.amount)}
                      </td>
                    </tr>
                    <tr>
                      <th>Sequence Number</th>
                      <td style={{ textAlign: "right" }}>
                        {checkData.seqNumber}
                      </td>
                    </tr>
                    <tr>
                      <th>Transaction Code</th>
                      <td style={{ textAlign: "right" }}>
                        {checkData.transactionCode}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                maxHeight: 670,
                overflow: "hidden",
                display: "grid",
                width: "60%",
                justifyContent: "center",
              }}
            >
              <h4 style={{ marginTop: 10 }}>Check Images</h4>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                }}
                ref={componentPrintRef}
              >
                {images.map((src, index) => (
                  <img
                    src={src}
                    key={index}
                    alt={index === 0 ? "Check Front" : "Check Back"}
                    title={index === 0 ? "Check Front" : "Check Back"}
                    onClick={() => openImageViewer(index)}
                    style={{
                      padding: "8px",
                      margin: "2px",
                      width: "750px", //width: `calc(100vw - 100px)`
                      height: "300px",
                      border: "solid 1px gray",
                    }}
                  />
                ))}
                <div
                  className="printedDiv"
                  style={{
                    display: "grid",
                    marginLeft: 40,
                    position: "absolute",
                    bottom: 20,
                  }}
                >
                  <table
                    style={{
                      width: "755px",
                      borderCollapse: "separate",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>Posting Date</th>
                        <th>Issue Date</th>
                        <th>Posting Account</th>
                        <th>Warrant Amount</th>
                        <th>Warrant Number</th>
                        <th>Bank Reference Number</th>
                      </tr>
                      <tr>
                        <td>{formatDate(checkData.postingDate)}</td>
                        <td>{formatDate(checkData.issueDate)}</td>
                        <td>{checkData.accountNumber}</td>
                        <td>{formatCurrency(checkData.amount)}</td>
                        <td>{checkData.serialNumber}</td>
                        <td>{checkData.routingNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div style={{ marginLeft: 700 }}>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handlePrint} autoFocus>
              Print
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewPopup;
