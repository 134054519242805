import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import './loading_panel.scss';

function LoadingScreen({ isLoading }) {
  return (
    <LoadPanel
      shadingColor="rgba(0, 0, 0, 0.4)"
      visible={isLoading}
      showIndicator={true}
      showPane={true}
      shading={true}
      closeOnOutsideClick={false}
    />
  );
}

export default LoadingScreen;