export const formatCurrency = (val) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(val);
};

export const formatDate = (val) => {
  if (val == null) return null;
  var date = new Date(val);
  return date.toLocaleDateString();
};

export const isArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const _arr1 = arr1.concat().sort();
  const _arr2 = arr2.concat().sort();

  for (let i = 0; i < _arr1.length; i++) {
    if (_arr1[i] !== _arr2[i]) {
      return false;
    }
  }

  return true;
};
