import React, { Component, createRef } from "react";
import CheckBox from "devextreme-react/check-box";
import CustomStore from "devextreme/data/custom_store";
//import { confirm } from "devextreme/ui/dialog";
import SecureLS from "secure-ls";
import "./users_table.scss";
import { isArrayEqual } from "../../_helper/helper";
import Button from "devextreme-react/button";
import DataGrid, {
  Column,
  Editing,
  Paging,
  FilterRow,
  Lookup,
  Item,
  Popup,
  Form,
  RequiredRule,
  EmailRule,
  PatternRule,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import AccountNumberCheckboxes from "./account_numbers_checkboxes";

const url = process.env.REACT_APP_API_URL + "/api";

class UsersTable extends Component {
  constructor(props) {
    super(props);

    //debugger;

    this.state = {
      userData: new CustomStore({
        key: "emailAddress",
        load: this.loadUsers,
        update: this.updateUser,
        insert: this.createUser,
      }),
      includeInactiveRecords: false,
      rolesData: [],
      departmentsData: [],
      currentEditRowKey: "",
    };
    this.childCheckboxRef = createRef();
    this.namePattern = /^[^0-9]+$/;
    

    this.active = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    this.dataGrid = null;
    this.addRow = this.addRow.bind(this);
  }

  componentDidMount() {
    fetch(url + "/Admin/GetAllNewUserCriteria", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          this.setState({
            rolesData: myJson.listRoles,
            departmentsData: myJson.listDepartments,
          });
        }
      });
  }

  onInitNewRow = (e) => {
    e.data.isActive = true;
  };

  onEditorPreparing = (e) => {
    if (
      e.dataField === "emailAddress" &&
      e.parentType === "dataRow" &&
      !e.row.isNewRow
    ) {
      e.editorOptions.disabled = true;
    }
    if (
      e.dataField === "departmentId" &&
      e.parentType === "dataRow" &&
      !e.row.isNewRow
    ) {
      this.getAccountNumbers(e.value, e.row.data.emailAddress);
    }
  };

  onEditingStart = (e) => {
    this.setState({ currentEditRowKey: e.key });
  };

  onSaving = (e) => {
    if (e.changes.length === 0) {
      var newAccountNumbers = this.childCheckboxRef.current.accountNumbersData
        .filter((x) => x.selected === true)
        .map((x) => x.accountID);
      var prevAccountNumbers =
        this.childCheckboxRef.current.prevAccountNumbersData
          .filter((x) => x.selected === true)
          .map((x) => x.accountID);

      const visibleRows = e.component.getVisibleRows();
      if (!isArrayEqual(newAccountNumbers, prevAccountNumbers)) {
        this.updateUser(
          this.state.currentEditRowKey,
          visibleRows.filter((x) => x.key === this.state.currentEditRowKey)[0]
            .data
        );
      }
    }
  };

  loadUsers = () => {
    return fetch(
      url +
        "/Admin/GetAllUsers?includeInactive=" +
        this.state.includeInactiveRecords,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          return myJson.listUser;
        }
      });
  };

  createUser = (values) => {
    const ls = new SecureLS();
    const userinfo = ls.get("userinfo");

    values.EmailAddress = values.emailAddress;
    values.CreatedBy = userinfo.emailAddress;
    values.AccountIDs = this.childCheckboxRef.current.accountNumbersData
      .filter((x) => x.selected === true)
      .map((x) => x.accountID);
    const body = JSON.stringify(values);

    return fetch(url + "/Admin/CreateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          return myJson;
        }
      });
  };

  updateUser = (key, values) => {
    // key is email
    values.EmailAddress = key;
    values.ModifiedBy = key;
    values.AccountIDs = this.childCheckboxRef.current.accountNumbersData
      .filter((x) => x.selected === true)
      .map((x) => x.accountID);
    const body = JSON.stringify(values);

    return fetch(url + "/Admin/UpdateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          return myJson;
        }
      });
  };
  
  includeInactiveRecordsValueChanged = (e) => {
    this.setState({ includeInactiveRecords: e.value });
    this.loadUsers();
    this.dataGrid.instance.refresh();
  };

  hideFields = (e) => {
    if (e.row.isNewRow) {
      e.component.columnOption("lastLoginDate", "formItem.visible", false);
    }
    if (!e.row.isNewRow) {
      e.component.columnOption("ResetHasla", "formItem.visible", true);
    }
  };

  getAccountNumbers = (value, email) => {
    // var departmentID = this.state.departmentsData[value - 1].departmentId;
    var departmentID = value;
    fetch(
      url +
        "/Admin/GetDepartmentAccountNumbers?departmentID=" +
        departmentID +
        "&userEmail=" +
        email,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          this.childCheckboxRef.current.setPrevAccountNumbersData(
            myJson.accountNumbers
          );
          this.childCheckboxRef.current.setAccountNumbersData(
            myJson.accountNumbers
          );
        }
      });
  };  

  addRow() {
    this.dataGrid.instance.addRow();
    this.dataGrid.instance.deselectAll();
  }

  render() {
    const { userData } = this.state;
    return (
      <div style={{ padding: "20px" }}>
        <br />
        <DataGrid
          id="gridContainer"
          dataSource={userData}
          allowColumnReordering={false}
          allowColumnResizing={true}
          columnResizingMode="widget"
          showBorders={true}
          showColumnLines={true}
          onInitNewRow={this.onInitNewRow}
          onEditorPreparing={this.onEditorPreparing}
          onEditingStart={this.onEditingStart}
          onSaving={this.onSaving}
          ref={(ref) => {
            this.dataGrid = ref;
          }}
          width="100%"
        >
          <FilterRow visible={true} />
          <Paging enabled={true} pageSize={25} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowAdding={true}
          >
            <Popup title="User Information" showTitle={true} />
            <Form>
              <Item itemType="group" caption="" colCount={2} colSpan={2}>
                <Item
                  dataField="employeeId"
                  editorOptions={{ maxLength: "25" }}
                />
                <Item
                  dataField="phoneNumber"
                  editorOptions={{ mask: "(000) 000-0000" }}
                />
                <Item
                  dataField="firstName"
                  editorOptions={{ maxLength: "25" }}
                />
                <Item
                  dataField="lastName"
                  editorOptions={{ maxLength: "25" }}
                />
                <Item
                  dataField="workAddress"
                  editorOptions={{ maxLength: "255" }}
                />
                <Item
                  dataField="emailAddress"
                  editorOptions={{ maxLength: "255" }}
                />
                <Item dataField="roleId" />
                <Item dataField="isActive" />
                <Item dataField="departmentId" />
              </Item>
              <Item itemType="group" caption="" colCount={2} colSpan={2}>
                <AccountNumberCheckboxes ref={this.childCheckboxRef} />
              </Item>
            </Form>
          </Editing>
          <Toolbar>
            <Item location="before">
              <CheckBox
                text="Include Inactive Accounts"
                value={this.state.includeInactiveRecords}
                onValueChanged={this.includeInactiveRecordsValueChanged}
              />
            </Item>
            <Item location="after">
              <Button
                id="addButton"
                text="Add User"
                hint="Add User"
                stylingMode="text"
                type="default"
                onClick={this.addRow}
              />
            </Item>
            <Item location="after" name="searchPanel" />
          </Toolbar>
          <Column
            dataField="employeeId"
            dataType="string"
            caption="Employee ID"
            allowSorting={true}
          >
            
          </Column>
          <Column
            dataField="firstName"
            dataType="string"
            caption="First Name"
            allowSorting={true}
          >
            <PatternRule
              message="The Input is Invalid"
              pattern={this.namePattern}
            />
            <RequiredRule />
          </Column>
          <Column
            dataField="lastName"
            dataType="string"
            caption="Last Name"
            allowSorting={true}
          >
            <PatternRule
              message="The Input is Invalid"
              pattern={this.namePattern}
            />
            <RequiredRule />
          </Column>
          <Column
            dataField="emailAddress"
            dataType="string"
            caption="E-Mail"
            allowSorting={true}
          >
            <RequiredRule />
            <EmailRule />
          </Column>
          <Column
            dataField="phoneNumber"
            dataType="string"
            caption="Phone Number"
            allowSorting={true}
          ></Column>
          <Column
            dataField="departmentId"
            dataType="string"
            caption="Department"
            allowSorting={true}
            setCellValue={(rowData, value, currentRowData) => {
              this.getAccountNumbers(value, currentRowData.emailAddress);
              rowData.departmentId = value;
            }}
          >
            <Lookup
              dataSource={this.state.departmentsData}
              displayExpr="name"
              valueExpr="departmentId"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField="workAddress"
            dataType="string"
            caption="Work Address"
            allowSorting={true}
          ></Column>
          <Column
            dataField="roleId"
            dataType="string"
            caption="Role"
            // visible={false}
          >
            <Lookup
              dataSource={this.state.rolesData}
              displayExpr="name"
              valueExpr="roleId"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField="isActive"
            dataType="boolean"
            caption="Enrollment Status"
            allowEditing={true}
            allowSorting={true}
          >
            <Lookup
              dataSource={this.active}
              displayExpr="text"
              valueExpr="value"
            />
          </Column>
          <Column type="buttons">
            <Button name="edit" hint="Edit" />
          </Column>
        </DataGrid>
      </div>
    );
  }
}

export default UsersTable;
