import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

const CheckAccountNumber = ({
  accountNumber,
  setAccountNumber,
  allowedAccounts,
  // departmentAccounts,
}) => {
  const AccountValidation = (selectedAccounts, allowedaccounts) => {
    if (selectedAccounts.every((v) => allowedaccounts.includes(v))) {
      return selectedAccounts;
    } else {
      return null;
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccountNumber(AccountValidation(value, allowedAccounts));
  };

  const formatAccountNumber = (accountNumber) => {
    return accountNumber.replace(/.(?=.{4})/g, "*");
  };

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <h5>Account Number</h5>
        <FormControl fullWidth>
          <Select
            labelId="account-number-label"
            multiple
            value={accountNumber}
            onChange={handleChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={formatAccountNumber(value)} />
                ))}
              </Box>
            )}
            size="small"
          >
            {allowedAccounts.map((accountnumber) => (
              <MenuItem
                key={accountnumber}
                value={accountnumber}
                // disabled={!allowedAccounts.includes(accountnumber)}
              >
                <Checkbox checked={accountNumber.indexOf(accountnumber) > -1} />
                <ListItemText primary={formatAccountNumber(accountnumber)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <hr />
    </>
  );
};

export default CheckAccountNumber;
