import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CheckWarrantPostDate from "../../components/check-image-filter/check_warrant_post_date";
import CheckWarrantIssueDate from "../../components/check-image-filter/check_warrant_issue_date";
import CheckAmount from "../../components/check-image-filter/check_amount";
import CheckWarrantNumber from "../../components/check-image-filter/check_warrant_number";
import CheckImageTable from "../../components/check-image-table/check_image_table";
import CheckAccountNumber from "../../components/check-image-filter/check_account_number.js";
import "./check_image_viewer.scss";
import LoadingScreen from "../../components/loading_panel";

const url = process.env.REACT_APP_API_URL + "/api";

const CheckImageViewer = () => {
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const [accountNumber, setAccountNumber] = useState([]);
  const [allowedAccounts, setAllowedAccounts] = useState([]);

  const [warrantPostDateComparison, setWarrantPostDateComparison] =
    useState(">");
  const [warrantPostDateFrom, setWarrantPostDateFrom] = useState(
    dayjs().subtract(5, "day")
  );
  const [warrantPostDateTo, setWarrantPostDateTo] = useState(
    dayjs().subtract(5, "day")
  );

  const [amountComparison, setAmountComparison] = useState("=");
  const [amountFrom, setAmountFrom] = useState(-1);
  const [amountTo, setAmountTo] = useState(-1);

  const [warrantNumberComparison, setWarrantNumberComparison] = useState("=");
  const [warrantNumberFrom, setWarrantNumberFrom] = useState("");
  const [warrantNumberTo, setWarrantNumberTo] = useState("");

  const [checkImageList, setCheckImageList] = useState([]);

  const [warrantIssueDateComparison, setWarrantIssueDateComparison] =
    useState(">");
  const [warrantIssueDateFrom, setWarrantIssueDateFrom] = useState(null);
  const [warrantIssueDateTo, setWarrantIssueDateTo] = useState(null);

  // call the API to collect allowed accounts of the current user
  useEffect(() => {
    //console.log("Fetching Allowed Account Access");
    try {
      const body = {
        Email: user.email,
      };
      fetch(url + "/View/GetAllowedAccountList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network Response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setAllowedAccounts(data.userAccountNumberList);
          // setDepartmentAccounts(data.departmentAccountList);
        });
    } catch (e) {
      console.log(e);
    }
  }, [user.email]);

  useEffect(() => {
    // Set selected accounts to all allowed accounts by default
    setAccountNumber(allowedAccounts);
  }, [allowedAccounts]);

  const handleFilterClick = () => {
    setIsLoading(true);

    try {
      const body = {
        accountNumbers: accountNumber,
        warrantPostDateComparison: warrantPostDateComparison,
        warrantPostDateFrom: warrantPostDateFrom,
        warrantPostDateTo: warrantPostDateTo,
        warrantIssueDateComparison: warrantIssueDateComparison,
        warrantIssueDateFrom: warrantIssueDateFrom,
        warrantIssueDateTo: warrantIssueDateTo,
        amountComparison: amountComparison,
        amountFrom: amountFrom,
        amountTo: amountTo,
        warrantNumberComparison: warrantNumberComparison,
        warrantNumberFrom: warrantNumberFrom,
        warrantNumberTo: warrantNumberTo,
        userEmail: user.email,
      };

      fetch(url + "/View/GetCheckImageList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          setCheckImageList(data.checkImageList);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleFilterReset = () => {
    setAccountNumber(allowedAccounts);
    setWarrantPostDateComparison(">");
    setWarrantPostDateFrom(dayjs().subtract(5, "day"));
    setWarrantPostDateTo(dayjs().subtract(5, "day"));
    setAmountComparison("=");
    setAmountFrom(-1);
    setAmountTo(-1);
    setWarrantNumberComparison("=");
    setWarrantNumberFrom("");
    setWarrantNumberTo("");
    setWarrantIssueDateComparison(">");
    setWarrantIssueDateFrom(null);
    setWarrantIssueDateTo(null);
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="float-child">
        <CheckAccountNumber
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          allowedAccounts={allowedAccounts}
          // departmentAccounts={departmentAccounts}
        />

        <CheckWarrantPostDate
          comparison={warrantPostDateComparison}
          setComparison={setWarrantPostDateComparison}
          warrantPostDateFrom={warrantPostDateFrom}
          setWarrantPostDateFrom={setWarrantPostDateFrom}
          warrantPostDateTo={warrantPostDateTo}
          setWarrantPostDateTo={setWarrantPostDateTo}
        />

        <CheckAmount
          comparison={amountComparison}
          setComparison={setAmountComparison}
          amountFrom={amountFrom}
          setAmountFrom={setAmountFrom}
          amountTo={amountTo}
          setAmountTo={setAmountTo}
        />

        <CheckWarrantNumber
          comparison={warrantNumberComparison}
          setComparison={setWarrantNumberComparison}
          warrantNumberFrom={warrantNumberFrom}
          setWarrantNumberFrom={setWarrantNumberFrom}
          warrantNumberTo={warrantNumberTo}
          setWarrantNumberTo={setWarrantNumberTo}
        />

        <CheckWarrantIssueDate
          comparison={warrantIssueDateComparison}
          setComparison={setWarrantIssueDateComparison}
          warrantIssueDateFrom={warrantIssueDateFrom}
          setWarrantIssueDateFrom={setWarrantIssueDateFrom}
          warrantIssueDateTo={warrantIssueDateTo}
          setWarrantIssueDateTo={setWarrantIssueDateTo}
        />

        <Stack direction="row" spacing={2}>
          <Button
            style={{
              backgroundColor: "#d9534f",
            }}
            fullWidth={true}
            color="error"
            variant="contained"
            onClick={handleFilterReset}
          >
            Reset
          </Button>
          <Button
            style={{
              backgroundColor: "#5cb85c",
            }}
            fullWidth={true}
            color="success"
            variant="contained"
            onClick={handleFilterClick}
          >
            Submit
          </Button>
        </Stack>
      </div>

      <div className="float-child">
        {/* <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                filter={filterFactory()}
                striped
                /> */}
        <CheckImageTable checkImageList={checkImageList} />
      </div>

      <LoadingScreen isLoading={isLoading} />
    </div>
  );
};
export default CheckImageViewer;
