import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AuthorizeUser } from './AuthorizeUser';

export const ProtectedRoute = ({ component, role, ...args }) => {
    return role ? (
    <Route
        component={withAuthenticationRequired(AuthorizeUser( { component, role } ), {
            onRedirecting: () => <div>loading...</div>,
        })}
        {...args}
    />) : (
        <Route
            component={withAuthenticationRequired(component, {
            onRedirecting: () => <div>loading...</div>,
        })}
        {...args}
    />
    );
};

//export default ProtectedRoute;