import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import CheckBox from "devextreme-react/check-box";
import "./users_table.scss";

//const url = process.env.REACT_APP_API_URL + "/api";

const AccountNumberCheckboxes = forwardRef((prop, ref) => {
  const [accountNumbersData, setAccountNumbersData] = useState([]);
  const [prevAccountNumbersData, setPrevAccountNumbersData] = useState([]);

  useImperativeHandle(
    ref,
    () => {
      return {
        accountNumbersData,
        setAccountNumbersData,
        prevAccountNumbersData,
        setPrevAccountNumbersData,
      };
    },
    [accountNumbersData, prevAccountNumbersData]
  );

  const onValueChanged = (index, e) => {
    let items = [...accountNumbersData];
    let changedItem = { ...items[index], selected: e.value };
    items[index] = changedItem;
    setPrevAccountNumbersData(accountNumbersData);
    setAccountNumbersData(items);
  };

  return (
    <>
      <div
        style={{
          fontSize: "12px",
          color: "#00000099",
          paddingTop: "18px",
          paddingLeft: "16px",
        }}
      >
        Select Department Accounts *
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "space-round",
          flexWrap: "wrap",
          paddingLeft: "16px",
        }}
      >
        {accountNumbersData !== undefined &&
          accountNumbersData.map((item, index) => {
            return (
              <div key={index} style={{ padding: "10px 50px 10px 0px" }}>
                <CheckBox
                  key={index}
                  text={item.accountNumber}
                  value={item.selected}
                  onValueChanged={(e) => {
                    onValueChanged(index, e);
                  }}
                />
              </div>
            );
          })}
      </div>
    </>
  );
});

export default AccountNumberCheckboxes;
